import { api_url } from 'config';
import noImageIcon from 'assets/images/no_image.svg';
import noAdvertiserIcon from 'assets/images/no_advertiser.svg'
import androidIcon from  'assets/images/platform_android.svg';
import iosIcon from 'assets/images/platform_ios.svg';
import ujAndroidIcon from 'assets/images/uj-android.svg';
import ujIosIcon from 'assets/images/uj-ios.svg';
import googlePlayIcon from 'assets/images/Google_Store.svg';
import galaxyStoreIcon from 'assets/images/galaxy-store.svg';
import appStoreIcon from 'assets/images/appstore.png';
import websiteIcon from 'assets/images/Website.svg';

export const getRsidValue = () => {
  if (location.hash) return location.hash.split("=")[1];
}

export const getHashKeyAndVal = () => {
  if (location.hash) {
    const hash = location.hash.split("=")
    return { key: hash[0].replace("#", ""), hash: hash[1] }
  }
  return { key: '', hash: '' }
}

export const urlBuilder = (reportType, extraHeader = '', queryParams = {}) => {
  let queryParamsString = Object.entries(queryParams).map((e) => { return `${e[0]}=${e[1]}`; }).join("&");

  if (queryParamsString) queryParamsString = ("?" + queryParamsString);

  if (!extraHeader || extraHeader === '') return encodeURI(`${api_url}/${reportType}${queryParamsString}`);

  return encodeURI(`${api_url}/${reportType}/${extraHeader}${queryParamsString}`);
}

export const isPathStructureIdentical = (pathname: string, path: string) => {
  const pathnameParts = pathname.split('/')
  const pathParts = path.split('/')

  return pathnameParts.every((part, ind) => part === pathParts[ind] || pathParts[ind]?.startsWith(':'))
}

export const formatNumberWithCommas = (num) => num.toLocaleString('en')

export function getTitles(values, package_names, itunes_ids) {
  let titles: any[] = [];
  values.forEach((e) => {
    if (e.startsWith("package:")) {
      const info = package_names[e.split(":")[1]];
      titles.push({ value: e, label: `${info.name} (${info.developer})`, platform: 'android', labelNote: e.platform });
    } else if (e.startsWith("itunes:")) {
      const info = itunes_ids[e.split(":")[1]];
      titles.push({ value: e, label: `${info.name} (${info.developer})`, platform: 'ios', labelNote: e.platform });
    } else if (e.startsWith("free:")) {
      titles.push({ value: e, label: `${e.split(":")[1]} (free text)` });
    }
  });

  return titles;
}

export const getMultipleTitles = (values, packageNames, itunesIds) => {
  return values.map(valueObj => {
    // values can be an array of ['a', 'b', 'c'] or an array of 
    // [{value: 'a', label: '...'} , {value: 'b', label: '...'}, ...]
    // because it comes from different. The next line address this and 
    // reformat the rule.value as [{ value: ... , label: ...}, ...]
    const value = valueObj['value'] || valueObj;
    if (value.startsWith("package:")) {
      const { name, developer } = packageNames[value.split(":")[1]];
      return { value, label: `${name}${developer ? ` (${developer})` : ''}`, labelNote: 'android', platform: 'android' };
    }
    if (value.startsWith("itunes:")) {
      const { name, developer } = itunesIds[value.split(":")[1]];
      return { value, label: `${name}${developer ? ` (${developer})` : ''}`, labelNote: 'ios', platform: 'ios' };
    }
    if (value.startsWith("free:")) {
      return { value, label: `${value.split(":")[1]} (free text)` };
    }
  });
}

export function buildRuleObject({ id, name, platform, advertiser, iconUrl, webDomain }) {
  const titleData: any = {};
  const rule: any = { platform };

  if (webDomain) {
    rule.ruleType = 'web_domain';
    rule.value = [{ value: webDomain, label: webDomain }];
  } else if (rule.platform === 'android') {
    rule.ruleType = 'title';
    rule.value = [{ value: `package:${id}`, label: `${name} (${advertiser})` }];
    titleData.id = {
      name: name,
      developer: advertiser,
      icon: iconUrl
    }
  } else if (rule.platform === 'ios') {
    rule.ruleType = 'title';
    rule.value = [{ value: `itunes:${id}`, label: `${name} (${advertiser})` }];
    titleData.id = {
      name: name,
      developer: advertiser,
      icon: iconUrl
    }
  }

  return { rule, titleData };
}

export function getTitleIcon(isWebAdvertiser, title, originalIcon) {
  let icon = originalIcon;
  if (isWebAdvertiser) { // website
    icon = noImageIcon;
  } else {
    if (title || originalIcon) { // regular
      icon = originalIcon
    } else { // nothing was found - unresolved 
      icon = noAdvertiserIcon;
    }
  }

  return icon;
}

export type Platform = 'android' | 'ios';

export const getPlatformIcon = (platform: Platform) => {
  if (platform === 'android') return androidIcon;
  if (platform === 'ios') return iosIcon;
}

export const getUserJourneyPlatformIcon = (platform: Platform) => {
  if (platform === 'android') return ujAndroidIcon;
  if (platform === 'ios') return ujIosIcon;
}

export const getStoreIcon = (storeUrl: string) => {
  if (storeUrl.includes('play.google.com')) return googlePlayIcon;
  if (storeUrl.includes('galaxystore.samsung.com')) return galaxyStoreIcon;
  if (storeUrl.includes('apps.apple.com')) return appStoreIcon;
  return websiteIcon;
}