import React, { Suspense, lazy, FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { COMPETITOR, PAGES_PROPS, RISKY_CONTENT_RULES, UserJourneyStatsTitle, MatchingDataReportTitle, RedirectIntelReportTitle, RedirectReportOldTitle } from 'constants/index';
import Spinner from './Spinner'
import PageNotFound from './PageNotFound';

const Creatives = lazy(() => import('pages/Creatives'));
const Campaigns = lazy(() => import('pages/Campaigns'));
const AdDetails = lazy(() => import('pages/AdDetails'));
const CreativesReport = lazy(() => import('components/CreativesReport'));
const RiskyContentSettings = lazy(() => import('pages/RiskyContentSettings'));
const Rules = lazy(() => import('pages/Rules'));
const Rule = lazy(() => import('pages/Rule'));
const IntelImpressionReport = lazy(() => import('pages/IntelImpressionReport'));
const UserJourneyStats = lazy(() => import('pages/UserJourneyStats'));
const MatchingDataReport = lazy(() => import('pages/MatchingDataReport'));
const RedirectIntelReport = lazy(() => import('pages/RedirectIntelReport'));
const UserJourneyReports = lazy(() => import('pages/UserJourneyReports'));
const UserJourneyNewReport = lazy(() => import('pages/UserJourneyNewReport'));
const UserJourneyTimeline = lazy(() => import('pages/UserJourneyTimeline'));

export const routes = [
    {
      key: 'main',
      exact: true,
      path: '/',
      Component: <PageNotFound />
    },
    {
      key: 'creatives',
      expect: true,
      path: '/creatives',
      title: PAGES_PROPS.creatives.title,
      section: true,
      Component: <Creatives {...PAGES_PROPS.creatives} />
    },
    {
      key: 'riskyContent',
      path: '/risky_content',
      title: PAGES_PROPS.risky_creatives.title,
      section: true,
      Component: <Creatives {...PAGES_PROPS.risky_creatives} />
    },
    {
      key: 'competitors',
      path: '/competitive',
      title: PAGES_PROPS.competitor_creatives.title,
      section: true,
      Component: <Creatives {...PAGES_PROPS.competitor_creatives} />
    },
    {
      key: 'campaigns',
      path: '/campaigns',
      title: PAGES_PROPS.campaigns.title,
      section: true,
      Component: <Campaigns {...PAGES_PROPS.campaigns} />
    },
    {
      key: 'creativeDetails',
      exact: true,
      path: '/creative_details/:fingerprint/:max_network_index?',
      Component: <AdDetails {...PAGES_PROPS.creative_details as any} />
    },
    {
      key: 'campaignDetails',
      path: '/campaign_details/:platform/:id',
      title: PAGES_PROPS.campaign_details.title,
      Component: <AdDetails {...PAGES_PROPS.campaign_details as any} />
    },
    {
      key: 'adReport',
      path: '/report_ads',
      title: 'Report Ads',
      Component: <CreativesReport />
    },
    {
      key: 'riskySettings',
      exact: true,
      path: '/manage/risky_content',
      title: PAGES_PROPS.riskyContent.title,
      section: true,
      Component: <RiskyContentSettings {...PAGES_PROPS.riskyContent} />
    },
    {
      key: 'riskyPreferences',
      path: '/manage/risky_content/preferences/:queries_user_id?',
      title: PAGES_PROPS.riskyContent.title,
      section: true,
      Component: <RiskyContentSettings {...PAGES_PROPS.riskyContent} />
    },
    {
      key: 'riskyRules',
      exact: true,
      path: '/manage/risky_content/rules/:queries_user_id?',
      title: PAGES_PROPS.riskyContent.title,
      section: true,
      Component: <RiskyContentSettings {...PAGES_PROPS.riskyContent} selectedTab={RISKY_CONTENT_RULES.key} />
    },
    {
      key: 'createRiskyRule',
      path: '/manage/risky_content/rules/create/:queries_user_id',
      title: PAGES_PROPS.riskyContent.breadcrumbLabelNew,
      Component: <Rule title={PAGES_PROPS.riskyContent.breadcrumbLabelNew} />
    },
    {
      key: 'editRiskyRule',
      path: '/manage/risky_content/rules/edit/:queries_user_id/:rule_id',
      Component: <Rule />
    },
    {
      key: 'competitorRules',
      exact: true,
      path: '/manage/competitors/:queries_user_id?',
      title: PAGES_PROPS.competitors.title,
      section: true,
      Component: <Rules dataType={COMPETITOR} {...PAGES_PROPS.competitors} />
    },
    {
      key: 'createCompetitorRule',
      path: '/manage/competitors/create/:queries_user_id',
      title: PAGES_PROPS.competitors.breadcrumbLabelNew,
      Component: <Rule title={PAGES_PROPS.competitors.breadcrumbLabelNew} />
    },
    {
      key: 'editCompetitorRule',
      path: '/manage/competitors/edit/:queries_user_id/:rule_id',
      Component: <Rule />
    },
    {
      key: 'intelImpressionReport',
      path: '/reports/intel_impressions',
      section: true,
      Component: <IntelImpressionReport {...PAGES_PROPS.intelImpressionReport} />
    },
    {
      key: 'userJourneyStats',
      path: '/user_journey_stats',
      title: UserJourneyStatsTitle,
      section: true,
      Component: <UserJourneyStats />
    },
    {
      key: 'matchingDataReport',
      path: '/matching_data_report',
      title: MatchingDataReportTitle,
      section: true,
      Component: <MatchingDataReport />
    },
    {
      key: 'redirectsReport',
      path: '/reports/redirects_report',
      title: RedirectIntelReportTitle,
      section: true,
      Component: <RedirectIntelReport variant='redirect_intel' />
    },
    {
      key: 'redirectsReportOld',
      path: '/reports/intel_redirects',
      title: RedirectReportOldTitle,
      section: true,
      Component: <RedirectIntelReport variant='redirect_old' />
    },
    {
      key: 'userJourneyReports',
      exact: true,
      path: '/user_journey/reports/:queries_user_id?',
      title: 'User Journey Reports',
      section: true,
      Component: <UserJourneyReports />
    },
    {
      key: 'createUserJourneyReport',
      path: '/user_journey/reports/create/:queries_user_id',
      title: 'Create New Report',
      Component: <UserJourneyNewReport />
    },
    {
      key: 'userJourneyTimeline',
      path: '/user_journey/timeline/:reportId',
      title: 'User Journey',
      Component: <UserJourneyTimeline />
    }
];

const AppRoutes: FC<{}> = () => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      {routes.map(({ key, exact, path, Component }) => (
        <Route key={key} exact={exact} path={path}>
          {Component}
        </Route>
      ))}

      <Redirect to="/" />
    </Switch>
  </Suspense>
);

export default AppRoutes;